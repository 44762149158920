import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";
import TiresItemscard from "../../components/Cards/tiresItemCards";
import Pagination from 'react-bootstrap/Pagination';
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import "rsuite/dist/rsuite.css";
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';
import { RangeSlider } from 'rsuite';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Index() {
  const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { brand_id, brand_name } = useParams();
  const currentLink = window.location.href;
  const url = new URL(currentLink);

  // Updated URL without query parameters
  const updatedUrl = url.toString();

  const [isHidden, setIsHidden] = useState(false);
  const [dataTires, setDataTires] = useState([]);
  const [isLoadingWheels, setIsLoadingWheels] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setoffsetCurrentPage] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(18000);
  const [sortingValue, setSortingValue] = useState(1);
  const MAX_PAGES_DISPLAY = 10;

  const [stockWheel, setStockWheel] = useState("");
  const [diameterArray, setDiameterArray] = useState([]);
  const [widthArray, setWidthArray] = useState([]);
  const [brandArray, setBradArray] = useState([]);
  const [brandNameArray, setBradNameArray] = useState([]);
  const [categoryArray, setcategoryArray] = useState([]);
  const [categoryNameArray, setcategoryNameArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [ratioArray, setratioArray] = useState([]);
  const [warranty, setwarranty] = useState("");
  const [runflat, setRunFlat] = useState("");
  const [studdable, setstuddable] = useState("");
  const [tiresizes, settiresizes] = useState("one");
  const [speedRatingArray, setSpeedRatingArray] = useState([]);
  const [speedLoadRange, setSpeedLoadRange] = useState([]);
  const [plyArray, setPlyArray] = useState([]);
  const [sideWallArray, setSideWallArray] = useState([]);
  const [milageRatingArray, setMilageRatingArray] = useState([]);




  const [openDiameter, setopenDiameter] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openWidth, setOpenWidth] = useState(false);
  const [openBrand, setOpenBrand] = useState(true);
  const [openPrice, setOpenPrice] = useState(false);
  const [openRatio, setopenRatio] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openSpeedRating, setOpenSpeedRating] = useState(false);
  const [openLoadRating, setOpenLoadRating] = useState(false);
  const [openSideWall, setOpenSideWall] = useState(false);
  const [openPly, setOpenPly] = useState(false);
  const [openRunFlat, setOpenRunFlat] = useState(false);
  const [openStuddable, setOpenStuddable] = useState(false);
  const [openTireSizes, setOpenTireSizes] = useState(false);
  const [openWarranty, setOpenWarranty] = useState(false);
  const [openMilageRating, setOpenMilageRating] = useState(false);


  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [filterClass, setFilterClass] = useState('');
  const [showMenuSide, setShowMenuSide] = useState("");
  const [isCheckedStock, setIsCheckedStock] = useState(true);
  const [maxPriceDefault, setMaxPriceDefault] = useState(null);
  const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
  const [isdata, setIsdata] = useState(true);

  const [isdataOnClick, setIsdataOnClick] = useState(true);



  useEffect(() => {
    if (brand_id) {
      setBradArray([...brandArray, brand_id]);
    }

    if (brand_name) {
      const categoryObject = { id: brand_id, name: brand_name };
      // console.log('categoryObject+++++++', categoryObject)
      setBradNameArray([...brandNameArray, categoryObject])
    }
  }, []);

  useEffect(() => {
    const apiKey = 'crQqKW9uFVL1bSu1uymaWx2UFTcvgdKE';
    let staggered = false;
    let fmkWithStaggered = null;

    if (vehical_details) {

      const selectedYear = vehical_details ? vehical_details.year : "";
      const selectedMake = vehical_details ? vehical_details.make : "";
      const selectedModel = vehical_details ? vehical_details.model : "";
      const selectedVariant = vehical_details ? vehical_details.variant : "";

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.fitmentatlas.com/v1.1/services/Vehicles/smartsizes/?YearId=${selectedYear}&MakeId=${selectedMake}&ModelId=${selectedModel}&ProductType=wheel&SubmodelID=${selectedVariant}`,
        headers: {
          'FG-ApiKey': apiKey
        }
      };

      axios.request(config)
        .then((response) => {
          const results = response.data;
          // const fmkWithStaggered = results.result[0].fmk;




          for (const obj of results.result) {
            if (obj.isStaggered === true) {
              fmkWithStaggered = obj.fmk;
              staggered = true;
              break; // Break the loop once we find the first object with isStaggered: true
            }
          }

          // If no object has isStaggered: true, get the fmk from the first object in the array
          if (fmkWithStaggered === null && results.result.length > 0) {
            fmkWithStaggered = results.result[0].fmk;
          }


          let config2 = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://api.fitmentatlas.com/v1.1/services/Vehicles/${fmkWithStaggered}`,
            headers: {
              'FG-ApiKey': apiKey
            }
          };


          axios.request(config2)
            .then((response) => {
              const results2 = response.data;
              let vehicleType = results2.result.vehicleTypeName;
              const vehicleChassis = results2.result.vehicleChassis;
              const liftedOptions = vehicleChassis.vehicleLiftedSizes;


              if (staggered === true) {
                navigate(`/tires/tires-with-vehicle-staggered?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);

              }

              if (staggered === false && vehicleType == 'Car' || (liftedOptions.length < 1 && staggered === false)) {
                navigate(`/tires/tires-with-vehicle?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);
              }

              if (liftedOptions.length > 0 && vehicleType == 'Truck') {
                navigate(`/tires/tires-with-vehicle-lifted?year=${selectedYear}&make=${selectedMake}&model=${selectedModel}&variant=${selectedVariant}`);

              }



            })
            .catch((error) => {
              console.log(error);
            });



        })
        .catch((error) => {
          console.log(error);
        });


    }
  }, []);

  useEffect(() => {



    const storedDiameter = localStorage.getItem('setDiameterArray');
    const storedWidth = localStorage.getItem('setWidthArray');
    const storedBrands = localStorage.getItem('setBradArray');
    const storedBrandsName = localStorage.getItem('setBradNameArray');
    const storedcategory = localStorage.getItem('setcategoryArray');
    const storedcategoryName = localStorage.getItem('setcategoryNameArray');
    const storedModel = localStorage.getItem('setModelArray');
    const storedMaxprice = localStorage.getItem('setMaxPrice');
    const storedMinprice = localStorage.getItem('setMinPrice');
    const storedFirstValue = localStorage.getItem('setFirstValue');
    const storedSecondValue = localStorage.getItem('setSecondValue');
    const storedSpeedRating = localStorage.getItem('setSpeedRatingArray');
    const storedSpeedLoad = localStorage.getItem('setSpeedLoadRange');
    const storedratio = localStorage.getItem('setratioArray');
    const storedPly = localStorage.getItem('setPlyArray');
    const storedSideWall = localStorage.getItem('setSideWallArray');
    const storedMilageRating = localStorage.getItem('setMilageRatingArray');
    const storedRunFlat = localStorage.getItem('setRunFlat');
    const storedstuddable = localStorage.getItem('setstuddable');
    const storedtiresizes = localStorage.getItem('settiresizes');
    const storedIsChecked = localStorage.getItem('setIsCheckedStock');
    const storedSortingValue = localStorage.getItem('setSortingValue');
    const storedCurrentPage = localStorage.getItem('setCurrentPage');
    const storedoffsetCurrentPage = localStorage.getItem('setoffsetCurrentPage');

    if (storedCurrentPage) {
      const parsedCurrentPage = JSON.parse(storedCurrentPage);


      setCurrentPage(parsedCurrentPage);
      setoffsetCurrentPage(parsedCurrentPage);

    } if (storedoffsetCurrentPage) {
      const parsedCurrentPage = JSON.parse(storedoffsetCurrentPage);

      setoffsetCurrentPage(parsedCurrentPage);

    }
    if (storedIsChecked) {
      const parsedStockWheel = JSON.parse(storedIsChecked);

      setIsCheckedStock(parsedStockWheel);

    }

    if (storedSortingValue) {
      const parsedSortingValue = JSON.parse(storedSortingValue);

      setSortingValue(parsedSortingValue);

    }

    if (storedDiameter) {
      const parsedDiameter = JSON.parse(storedDiameter);
      setDiameterArray(parsedDiameter);
      if (parsedDiameter.length > 0) {
        setopenDiameter(true);

      }
    }

    if (storedWidth) {
      const parsedWidth = JSON.parse(storedWidth);
      setWidthArray(parsedWidth);
      if (parsedWidth.length > 0) {
        setOpenWidth(true);



      }
    }
    if (storedBrands) {
      const parsedBrands = JSON.parse(storedBrands);
      setBradArray(parsedBrands);
      if (parsedBrands.length > 0) {
        setOpenBrand(true);


      }

    }
    if (storedBrandsName) {
      const parsedBrandsName = JSON.parse(storedBrandsName);
      setBradNameArray(parsedBrandsName);
      if (parsedBrandsName.length > 0) {
        setOpenBrand(true);


      }

    }
    if (storedcategory) {
      const parsedcategory = JSON.parse(storedcategory);
      setcategoryArray(parsedcategory);
      if (parsedcategory.length > 0) {
        setOpenCategory(true);


      }

    }
    if (storedcategoryName) {
      const parsedcategoryName = JSON.parse(storedcategoryName);
      setcategoryNameArray(parsedcategoryName);
      if (parsedcategoryName.length > 0) {
        setOpenCategory(true);


      }

    }
    if (storedModel) {
      const parsedModel = JSON.parse(storedModel);
      setModelArray(parsedModel);
      if (parsedModel.length > 0) {
        setOpenModel(true);

      }

    }
    if (storedMaxprice) {
      const parsedMaxprice = JSON.parse(storedMaxprice);
      setMaxPrice(parsedMaxprice);
      //  console.log("parsedMaxprice++++",parsedMaxprice)

    }
    if (storedMinprice) {
      const parsedMinprice = JSON.parse(storedMinprice);
      setMinPrice(parsedMinprice);
      //   console.log("parsedMinprice=====",parsedMinprice)


    }
    if (storedFirstValue) {
      const parsedFirstValue = JSON.parse(storedFirstValue);
      setFirstValue(parsedFirstValue);
      //  console.log("parsedMaxprice++++",parsedFirstValue)

    }
    if (storedSecondValue) {
      const parsedSecondValue = JSON.parse(storedSecondValue);
      setSecondValue(parsedSecondValue);

    }
    if (storedSpeedRating) {
      const parsedSpeedRating = JSON.parse(storedSpeedRating);
      setSpeedRatingArray(parsedSpeedRating);
      if (parsedSpeedRating.length > 0) {
        setOpenSpeedRating(true);

      }
    }

    if (storedSpeedLoad) {
      const parsedSpeedLoad = JSON.parse(storedSpeedLoad);
      setSpeedLoadRange(parsedSpeedLoad);
      if (parsedSpeedLoad.length > 0) {
        setOpenLoadRating(true);

      }
    }

    if (storedratio) {
      const parsedratio = JSON.parse(storedratio);
      setratioArray(parsedratio);
      if (parsedratio.length > 0) {
        setopenRatio(true);

      }
    }

    if (storedPly) {
      const parsedPly = JSON.parse(storedPly);
      setPlyArray(parsedPly);
      if (parsedPly.length > 0) {
        setOpenPly(true);

      }
    }
    if (storedSideWall) {
      const parsedSideWall = JSON.parse(storedSideWall);
      setSideWallArray(parsedSideWall);
      if (parsedSideWall.length > 0) {
        setOpenSideWall(true);

      }
    }
    if (storedMilageRating) {
      const parsedMilageRating = JSON.parse(storedMilageRating);
      setMilageRatingArray(parsedMilageRating);
      if (parsedMilageRating.length > 0) {
        setOpenMilageRating(true);

      }
    }
    if (storedRunFlat) {
      const parsedRunFlat = JSON.parse(storedRunFlat);
      setRunFlat(parsedRunFlat);
      if (parsedRunFlat.length > 0) {
        setOpenRunFlat(true);

      }
    }

    if (storedstuddable) {
      const parsedstuddable = JSON.parse(storedstuddable);
      setstuddable(parsedstuddable);
      if (parsedstuddable.length > 0) {
        setOpenStuddable(true);

      }
    }

    if (storedtiresizes) {
      const parsedtiresizes = JSON.parse(storedtiresizes);
      settiresizes(parsedtiresizes);
      if (parsedtiresizes.length > 0) {
        setOpenTireSizes(true);

      }
    }


  }, []);

  useEffect(() => {

    const storedProductPage = localStorage.getItem('inProductPage');

    const ProductPage = JSON.parse(storedProductPage);


    // console.log("isMainLoadPage+++", ProductPage)
    if (ProductPage == true) {


      const storedDiameter = localStorage.getItem('setDiameterArray');
      const storedWidth = localStorage.getItem('setWidthArray');
      const storedcategory = localStorage.getItem('setcategoryArray');
      const storedcategoryName = localStorage.getItem('setcategoryNameArray');
      const storedBrands = localStorage.getItem('setBradArray');
      const storedBrandsName = localStorage.getItem('setBradNameArray');
      const storedSpeedRating = localStorage.getItem('setSpeedRatingArray');
      const storedSpeedLoad = localStorage.getItem('setSpeedLoadRange');
      const storedratio = localStorage.getItem('setratioArray');
      const storedPly = localStorage.getItem('setPlyArray');
      const storedModel = localStorage.getItem('setModelArray');
      const storedMaxprice = localStorage.getItem('setMaxPrice');
      const storedMinprice = localStorage.getItem('setMinPrice');
      const storedSideWall = localStorage.getItem('setSideWallArray');
      const storedMilageRating = localStorage.getItem('setMilageRatingArray');
      const storedRunFlat = localStorage.getItem('setRunFlat');
      const storedstuddable = localStorage.getItem('setstuddable');
      const storedtiresizes = localStorage.getItem('settiresizes');
      const storedCurrentPage = localStorage.getItem('setCurrentPage');
      const storedoffsetCurrentPage = localStorage.getItem('setoffsetCurrentPage');

      const parsedDiameter = JSON.parse(storedDiameter);
      const parsedWidth = JSON.parse(storedWidth);
      const parsedcategory = JSON.parse(storedcategory);
      const parsedcategoryName = JSON.parse(storedcategoryName);
      const parsedBrands = JSON.parse(storedBrands);
      const parsedBrandsName = JSON.parse(storedBrandsName);
      const parsedSpeedRating = JSON.parse(storedSpeedRating);
      const parsedSpeedLoad = JSON.parse(storedSpeedLoad);
      const parsedratio = JSON.parse(storedratio);
      const parsedPly = JSON.parse(storedPly);
      const parsedModel = JSON.parse(storedModel);
      const parsedMaxprice = JSON.parse(storedMaxprice);
      const parsedMinprice = JSON.parse(storedMinprice);
      const parsedSideWall = JSON.parse(storedSideWall);
      const parsedMilageRating = JSON.parse(storedMilageRating);
      const parsedRunFlat = JSON.parse(storedRunFlat);
      const parsedstuddable = JSON.parse(storedstuddable);
      const parsedtiresizes = JSON.parse(storedtiresizes);

      const parsedCurrentPage = JSON.parse(storedCurrentPage);
      const parsedoffsetCurrentPage = JSON.parse(storedoffsetCurrentPage);

      //  console.log("parsedMaxprice++++",parsedMaxprice,"parsedMinprice+++++",parsedMinprice)

      // console.log("parsedDiameter",parsedDiameter,"parsedBrands+++",parsedBrands,"widthArray+++",widthArray,"sortingValue+++",sortingValue,"firstValue+++",firstValue,"secondValue",secondValue,"stockWheel+++",stockWheel,"modelArray+++",modelArray,"isCheckedStock+++",isCheckedStock,"parsedMinprice",parsedMinprice,"parsedMaxprice",parsedMaxprice);

      //   console.log("isMainLoadPage+++",ProductPage)
      if ((brand_name && brand_id) || parsedCurrentPage != null || parsedoffsetCurrentPage != null || parsedstuddable != null || parsedRunFlat != null || parsedMilageRating != null || parsedSideWall != null || parsedMilageRating != null || parsedDiameter != null || parsedWidth != null || parsedcategory != null || parsedcategoryName != null || parsedBrands != null || parsedBrandsName != null || parsedSpeedRating != null || parsedSpeedLoad != null || parsedratio != null || parsedPly != null || parsedModel != null || parsedMaxprice != null || parsedMinprice != null) {
        localStorage.removeItem('inProductPage');
        setIsdataOnClick(prevState => !prevState);
        //   console.log("diameterArray+++",diameterArray,"brandArray+++",brandArray,"widthArray+++",widthArray,"sortingValue+++",sortingValue,"firstValue+++",firstValue,"secondValue",secondValue,"stockWheel+++",stockWheel,"modelArray+++",modelArray,"isCheckedStock+++",isCheckedStock);
        if (milageRatingArray.length > 0 || sideWallArray.length > 0 || speedLoadRange.length > 0 || speedRatingArray.length > 0 || studdable.length > 0 || runflat.length > 0 || ratioArray.length > 0 || diameterArray.length > 0 || brandArray.length > 0 || categoryArray.length > 0 || categoryNameArray.length > 0 || widthArray.length > 0 || modelArray.length > 0 || plyArray.length > 0) {

          fetchTiresData();

        } else {
          return;
        }


      } else {
        fetchTiresData();
      }

    } else if (brand_name && brand_id) {
      if (milageRatingArray.length > 0 || sideWallArray.length > 0 || speedLoadRange.length > 0 || speedRatingArray.length > 0 || studdable.length > 0 || runflat.length > 0 || ratioArray.length > 0 || diameterArray.length > 0 || brandArray.length > 0 || categoryArray.length > 0 || categoryNameArray.length > 0 || widthArray.length > 0 || modelArray.length > 0 || plyArray.length > 0) {
        fetchTiresData();
      }
    } else {
      fetchTiresData();
    }


  }, [isdataOnClick]);



  const fetchTiresData = async () => {
    document.body.classList.add('full-screen-loader');
    setIsLoadingWheels(true);
    const limit = 28;

    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        credentials: 'include', // Send cookies with the request
        withCredentials: true,
        url: `${mainUrl}/api/tires/getAllTiresData?diameter=${diameterArray}&brand=${brandArray}&width=${widthArray}&limit=${limit}&offset=${offset}&sorting=${sortingValue}&startingPrice=${firstValue}&endPrice=${secondValue}&stockWheel=${stockWheel}&style=${modelArray}&isCheckedStock=${isCheckedStock}&categoryArray=${categoryArray}&ratioArray=${ratioArray}&warranty=${warranty}&runflat=${runflat}&studdable=${studdable}&speedRatingArray=${speedRatingArray}&speedLoadRange=${speedLoadRange}&plyArray=${plyArray}&sideWallArray=${sideWallArray}&milageRatingArray=${milageRatingArray}&tiresizes=${tiresizes}`,
      };
      axios.request(config)
        .then((response) => {
          setDataTires(response.data);
          if (response.data.status == 500) {
            setIsdata(false);

          }
          if (brand_id && brand_name) {
            let isBrandFound = false;

            for (let i = 0; i < response.data.filters.brandTableData.length; i++) {
              if (response.data.filters.brandTableData[i].name == brand_name && response.data.filters.brandTableData[i].brand_id == brand_id) {

                isBrandFound = true;
                break;
              }
            }
            if (!isBrandFound) {
              setIsdata(false);
            }
          }

          setIsLoadingWheels(false);
          setTotalPages(response.data.totalPages);
          setTotalProducts(response.data.count);
          window.scrollTo({ top: 400, behavior: 'smooth' });
          setIsHidden(true);
          document.body.classList.remove('full-screen-loader');
          setMaxPriceDefault((Math.ceil(response.data.defaultMaxPrice)) + 1);

          const storedMaxprice = localStorage.getItem('setMaxPrice');
          const storedMinprice = localStorage.getItem('setMinPrice');
          if (storedMaxprice <= 0) {

            setMaxPrice(Math.ceil(response.data.maximumPrice));
          } else {

            setOpenPrice(true);
          }
          if (storedMinprice <= 0) {
            setMinPrice(Math.floor(response.data.minimumPrice));
          } else {
            setOpenPrice(true);
          }


        })
        .catch((error) => {
          setIsdata(false);

          setIsHidden(true);
          document.body.classList.remove('full-screen-loader');
          // navigate(`/error`);

        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoadingWheels(false);
      setIsHidden(true);
      document.body.classList.remove('full-screen-loader');
      // navigate(`/error`);

    }
  };

  const handleSorting = (event) => {
    const sortingValue = event.target.value;
    setSortingValue(sortingValue);
    localStorage.setItem('setSortingValue', JSON.stringify(sortingValue));
    setIsdataOnClick(prevState => !prevState);
    setCurrentPage(1);

  };

  const handlePageChange = (page) => {
    const limit = 28;
    const offsets = (page - 1) * limit; // Calculate the offset based on the current page and limit

    setCurrentPage(page);
    setoffsetCurrentPage(offsets);
    document.body.classList.add('full-screen-loader');
    localStorage.setItem('setCurrentPage', JSON.stringify(page));
    localStorage.setItem('setoffsetCurrentPage', JSON.stringify(offsets));
    window.scrollTo({ top: 400, behavior: 'smooth' });
    setIsdataOnClick(prevState => !prevState);
  };

  const getPageNumbers = () => {
    const totalDisplayPages = Math.min(totalPages, MAX_PAGES_DISPLAY);
    const middlePage = Math.ceil(totalDisplayPages / 2);
    let startPage = Math.max(1, currentPage - middlePage + 1);

    // Ensure the start page does not exceed the available pages
    if (startPage + totalDisplayPages > totalPages) {
      startPage = Math.max(1, totalPages - totalDisplayPages + 1);
    }

    return Array.from({ length: totalDisplayPages }, (_, index) => startPage + index);
  };







  const handleDiameterCheckboxChange = (event) => {
    setIsHidden(true);
    const sdiameter = event.target.value;
    if (diameterArray.includes(sdiameter)) {
      const updatedDiameter = diameterArray.filter((id) => id !== sdiameter);
      setDiameterArray(updatedDiameter);
      localStorage.setItem('setDiameterArray', JSON.stringify(updatedDiameter));

    } else {
      const updatedDiameter = [...diameterArray, sdiameter];
      setDiameterArray(updatedDiameter);
      localStorage.setItem('setDiameterArray', JSON.stringify(updatedDiameter));

    }

    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');

  };
  const handleWidthCheckboxChange = (event) => {
    setIsHidden(true);
    const sWidth = event.target.value;
    if (widthArray.includes(sWidth)) {
      const updatedWidth = widthArray.filter((id) => id !== sWidth);
      setWidthArray(updatedWidth);
      localStorage.setItem('setWidthArray', JSON.stringify(updatedWidth));

    } else {
      const updatedWidth = [...widthArray, sWidth]
      setWidthArray(updatedWidth);
      localStorage.setItem('setWidthArray', JSON.stringify(updatedWidth));

    }

    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };
  const handleBrandCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (brandArray.includes(sBrand)) {
      const updatedBrand = brandArray.filter((id) => id !== sBrand);
      setBradArray(updatedBrand);
      localStorage.setItem('setBradArray', JSON.stringify(updatedBrand));

    } else {
      const updatedBrand = [...brandArray, sBrand];
      setBradArray(updatedBrand);
      localStorage.setItem('setBradArray', JSON.stringify(updatedBrand));
    }
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = brandNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      const updatedBrandsName = brandNameArray.filter((obj) => obj.id !== sBrand);
      setBradNameArray(updatedBrandsName);
      localStorage.setItem('setBradNameArray', JSON.stringify(updatedBrandsName));
    } else {
      const updatedBrandsName = [...brandNameArray, categoryObject];
      setBradNameArray(updatedBrandsName);
      localStorage.setItem('setBradNameArray', JSON.stringify(updatedBrandsName));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    if (brand_id == sBrand) {
      navigate(`/tires`);
    }
  };

  const handleCategoryCheckboxChange = (event) => {
    setIsHidden(true);
    const sBrand = event.target.value;
    const brandIdName = event.target.name;

    if (categoryArray.includes(sBrand)) {
      const updatedCategories = categoryArray.filter((id) => id !== sBrand);
      setcategoryArray(updatedCategories);
      localStorage.setItem('setcategoryArray', JSON.stringify(updatedCategories));
    } else {
      const updatedCategories = [...categoryArray, sBrand];
      setcategoryArray(updatedCategories);
      localStorage.setItem('setcategoryArray', JSON.stringify(updatedCategories));

    }
    const categoryObject = { id: sBrand, name: brandIdName };
    const categoryObjectIndex = categoryNameArray.findIndex(
      (obj) => obj.id === sBrand
    );
    if (categoryObjectIndex !== -1) {
      const updatedCategoriesName = categoryNameArray.filter((obj) => obj.id !== sBrand);
      setcategoryNameArray(updatedCategoriesName);
      localStorage.setItem('setcategoryNameArray', JSON.stringify(updatedCategoriesName));

    } else {
      const updatedCategoriesName = [...categoryNameArray, categoryObject];
      setcategoryNameArray(updatedCategoriesName);
      localStorage.setItem('setcategoryNameArray', JSON.stringify(updatedCategoriesName));
    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };

  const handleModelCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (modelArray.includes(sModle)) {
      const updatedModle = modelArray.filter((id) => id !== sModle)
      setModelArray(updatedModle);
      localStorage.setItem('setModelArray', JSON.stringify(updatedModle));
    } else {
      const updatedModle = [...modelArray, sModle];
      setModelArray(updatedModle);
      localStorage.setItem('setModelArray', JSON.stringify(updatedModle));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };

  const handleSpeedRatingCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (speedRatingArray.includes(sModle)) {
      const updatedRating = speedRatingArray.filter((id) => id !== sModle)
      setSpeedRatingArray(updatedRating);
      localStorage.setItem('setSpeedRatingArray', JSON.stringify(updatedRating));

    } else {
      const updatedRating = [...speedRatingArray, sModle];
      setSpeedRatingArray(updatedRating);
      localStorage.setItem('setSpeedRatingArray', JSON.stringify(updatedRating));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };


  const handleLoadRangeCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (speedLoadRange.includes(sModle)) {
      const updatedSpeedLoad = speedLoadRange.filter((id) => id !== sModle);
      setSpeedLoadRange(updatedSpeedLoad);
      localStorage.setItem('setSpeedLoadRange', JSON.stringify(updatedSpeedLoad));

    } else {
      const updatedSpeedLoad = [...speedLoadRange, sModle];
      setSpeedLoadRange(updatedSpeedLoad);
      localStorage.setItem('setSpeedLoadRange', JSON.stringify(updatedSpeedLoad));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };



  const handleRatioCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (ratioArray.includes(sModle)) {
      const updatedratio = ratioArray.filter((id) => id !== sModle);
      setratioArray(updatedratio);
      localStorage.setItem('setratioArray', JSON.stringify(updatedratio));

    } else {
      const updatedratio = [...ratioArray, sModle];
      setratioArray(updatedratio);
      localStorage.setItem('setratioArray', JSON.stringify(updatedratio));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };




  const handlePlyCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (plyArray.includes(sModle)) {
      const updatedPly = plyArray.filter((id) => id !== sModle)
      setPlyArray(updatedPly);
      localStorage.setItem('setPlyArray', JSON.stringify(updatedPly));

    } else {
      const updatedPly = [...plyArray, sModle];
      setPlyArray(updatedPly);
      localStorage.setItem('setPlyArray', JSON.stringify(updatedPly));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };




  const handleWarrentyCheckboxChange = (event) => {

    const newValue = event.target.value;
    if (warranty === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      setwarranty(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      setwarranty(newValue);
    }

  };

  const handleSideWallCheckboxChange = (event) => {
    setIsHidden(true);
    const sModle = event.target.value;
    if (sideWallArray.includes(sModle)) {
      const updatedSideWall = sideWallArray.filter((id) => id !== sModle);
      setSideWallArray(updatedSideWall);
      localStorage.setItem('setSideWallArray', JSON.stringify(updatedSideWall));

    } else {
      const updatedSideWall = [...sideWallArray, sModle];
      setSideWallArray(updatedSideWall);
      localStorage.setItem('setSideWallArray', JSON.stringify(updatedSideWall));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };


  const handleMilageRatingCheckboxChange = (event) => {
    setIsHidden(true);
    const value = event.target.value;
    if (milageRatingArray.includes(value)) {
      const updatedMilageRating = milageRatingArray.filter((id) => id !== value);
      setMilageRatingArray(updatedMilageRating);
      localStorage.setItem('setMilageRatingArray', JSON.stringify(updatedMilageRating));

    } else {
      const updatedMilageRating = [...milageRatingArray, value]
      setMilageRatingArray(updatedMilageRating);
      localStorage.setItem('setMilageRatingArray', JSON.stringify(updatedMilageRating));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };


  const handleRunFlatCheckboxChange = (event) => {
    // setIsHidden(true);
    // setRunFlat(event.target.value)


    const newValue = event.target.value;
    if (runflat === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      setRunFlat(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      setRunFlat(newValue);
      localStorage.setItem('setRunFlat', JSON.stringify(newValue));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };


  const handleStuddableCheckboxChange = (event) => {
    // setIsHidden(true);
    // setstuddable(event.target.value)
    const newValue = event.target.value;
    if (studdable === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      setstuddable(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      setstuddable(newValue);
      localStorage.setItem('setstuddable', JSON.stringify(newValue));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };

  const handleTireSizesCheckboxChange = (event) => {
    // setIsHidden(true);
    // setstuddable(event.target.value)
    const newValue = event.target.value;
    if (tiresizes === newValue) {
      // If it's the same value, uncheck
      setIsHidden(false);
      settiresizes(null); // Assuming null represents "not selected"
    } else {
      // If it's a different value, check and hide
      setIsHidden(true);
      settiresizes(newValue);
      localStorage.setItem('settiresizes', JSON.stringify(newValue));

    }
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
  };





  const clearAllFilters = () => {
    localStorage.removeItem('setDiameterArray');
    localStorage.removeItem('setWidthArray');
    localStorage.removeItem('setPatternArray');
    localStorage.removeItem('setBradArray');
    localStorage.removeItem('setBradNameArray');
    localStorage.removeItem('setcategoryArray');
    localStorage.removeItem('setcategoryNameArray');
    localStorage.removeItem('setModelArray');
    localStorage.removeItem('setMaxPrice');
    localStorage.removeItem('setMinPrice');
    localStorage.removeItem('setFirstValue');
    localStorage.removeItem('setSecondValue');
    localStorage.removeItem('setSpeedLoadRange');
    localStorage.removeItem('setSpeedRatingArray');
    localStorage.removeItem('setratioArray');
    localStorage.removeItem('setPlyArray');
    localStorage.removeItem('setSideWallArray');
    localStorage.removeItem('setMilageRatingArray');
    localStorage.removeItem('setRunFlat');
    localStorage.removeItem('setstuddable');
    localStorage.removeItem('settiresizes');
    localStorage.removeItem('settiresizes');
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    setBradArray([]);
    setcategoryArray([]);
    setBradNameArray([]);
    setcategoryNameArray([]);
    setratioArray([]);
    setDiameterArray([]);
    setWidthArray([]);
    setBradNameArray([]);
    setcategoryArray([]);
    setModelArray([]);
    setratioArray([]);
    setFirstValue([]);
    setSecondValue([]);
    setwarranty("");
    setRunFlat("");
    setstuddable("");
    setSpeedRatingArray([]);
    setSpeedLoadRange([]);
    setPlyArray([]);
    setSideWallArray([])
    setMilageRatingArray([])
    setIsdataOnClick(prevState => !prevState);
    navigate(`/tires`);


  }
  const handleRemoveDiameter = (id) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedDiameter = diameterArray.filter((id) => id !== id);
    setDiameterArray(updatedDiameter);
    localStorage.setItem('setDiameterArray', JSON.stringify(updatedDiameter));

  };
  const handleRemoveWidth = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = widthArray.filter((id) => id !== width);
    setWidthArray(updatedCategories);
    localStorage.setItem('setWidthArray', JSON.stringify(updatedCategories));

  };
  const handleRemoveModel = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = modelArray.filter((id) => id !== width);
    setModelArray(updatedCategories);
    localStorage.setItem('setModelArray', JSON.stringify(updatedCategories));

  };
  const handleRemoveBrand = (width) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = brandArray.filter((id) => id !== width);
    setBradArray(updatedCategories);
    localStorage.setItem('setBradArray', JSON.stringify(updatedCategories));

    const updatedBrandNames = brandNameArray.filter((name) => name.id !== width);
    setBradNameArray(updatedBrandNames);
    localStorage.setItem('setBradNameArray', JSON.stringify(updatedBrandNames));
    if (brand_id == width) {
      navigate(`/tires`);
    }
  };

  const handleRemoveRatio = (ratio) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = ratioArray.filter((id) => id !== ratio);
    setratioArray(updatedCategories);
    localStorage.setItem('setratioArray', JSON.stringify(updatedCategories));

  };

  const handleRemoveLoadRange = (loadrange) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = speedLoadRange.filter((id) => id !== loadrange);
    setSpeedLoadRange(updatedCategories);
    localStorage.setItem('setSpeedLoadRange', JSON.stringify(updatedCategories));

  };
  const handleRemovedSpeedRating = (speedRating) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = speedRatingArray.filter((id) => id !== speedRating);
    setSpeedRatingArray(updatedCategories);
    localStorage.setItem('setSpeedRatingArray', JSON.stringify(updatedCategories));

  };
  const handleRemovedPly = (ply) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = plyArray.filter((id) => id !== ply);
    setPlyArray(updatedCategories);
    localStorage.setItem('setPlyArray', JSON.stringify(updatedCategories));

  };



  const handleRemovedSideWall = (ply) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = sideWallArray.filter((id) => id !== ply);
    setSideWallArray(updatedCategories);
    localStorage.setItem('setSideWallArray', JSON.stringify(updatedCategories));

  };
  const handleRemovedMilageRating = (ply) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    const updatedCategories = milageRatingArray.filter((id) => id !== ply);
    setMilageRatingArray(updatedCategories);
    localStorage.setItem('setMilageRatingArray', JSON.stringify(updatedCategories));

  };






  const showFiltersClick = () => {
    setFilterClass('active-filter')
    document.body.classList.add('home-layout')

  }
  const hideFilterClass = () => {
    setFilterClass('')
    document.body.classList.remove('home-layout')
  }

  const setValueMenuSideBar = (value) => {
    setShowMenuSide(value)
  }




  const PRODUCT_PER_PAGE = 28;
  const startIndex = (currentPage - 1) * PRODUCT_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + PRODUCT_PER_PAGE - 1, totalProducts);
  // const optionsAvailbility = [
  //   { value: "zero", label: "ALL" },
  //   { value: "one", label: "1" },
  //   { value: "two", label: "2" },
  //   { value: "three", label: "3" },
  //   { value: "four", label: "4+" },

  // ];
  const [cartItems, setCartItems] = useState("");

  useEffect(() => {
    const storedCartItems = localStorage.getItem('totalCartItems');

    if (storedCartItems) {
      const parsedCartItems = JSON.parse(storedCartItems);
      const numCartItems = Object.values(parsedCartItems);
      const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartItems(sum);
    }
  }, []);

  const handleChangeStockAvail = () => {
    setIsCheckedStock(!isCheckedStock)
    localStorage.setItem('setIsCheckedStock', JSON.stringify(!isCheckedStock));
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    setIsdataOnClick(prevState => !prevState);

  }

  const handleChangeSlider = (value) => {
    setCurrentPage(1);
    setoffsetCurrentPage(0);
    localStorage.removeItem('setoffsetCurrentPage');
    localStorage.removeItem('setCurrentPage');
    setFirstValue(value[0]);
    setSecondValue(value[1]);

    setMinPrice(value[0]);
    setMaxPrice(value[1]);
    if (value[1]) {

      localStorage.setItem('setMaxPrice', JSON.stringify(value[1]));
      localStorage.setItem('setSecondValue', JSON.stringify(value[1]));
    }
    if (value[0]) {


      localStorage.setItem('setMinPrice', JSON.stringify(value[0]));
      localStorage.setItem('setFirstValue', JSON.stringify(value[0]));
    }

  };

  const mileageRating = [
    20000, 25000, 30000,
    35000, 40000, 45000,
    50000, 55000, 60000,
    62000, 65000, 70000,
    75000, 80000, 85000,
    90000
  ];
  const goBackToHomepage = () => {
    navigate('/');
  };


  function onclickFilter() {
    setIsdataOnClick(prevState => !prevState);
    hideFilterClass();
  }

  return (
    <>
      {isdata ?
        <Helmet>
          <title>{brand_name ? brand_name : "Tires"}</title>
          <meta name="description" content={`Explore the ultimate in tire performance and reliability delivered by ${brand_name ? brand_name : "our tires"}, ensuring safety and confidence on every journey.`} />
          <link rel="canonical" href={updatedUrl} />
        </Helmet> :
        <Helmet>
          <title>Tires</title>
          <meta name="description" content={`Experience superior traction and handling with Extreme Performance high-quality tires, engineered for performance and reliability.`} />
          <link rel="canonical" href={updatedUrl} />
        </Helmet>}
      <TOPBAR isHidden={isHidden} />
      <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
      <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />
      {isdata ? (
        <>
          <section>
            <div className="main-inner-banner">
              <div className="parformane-bar">
                <div className="container d-flex justify-content-between">
                  <div>

                    <ul className="p-0 m-0 listing-parmofamne">
                      <li><a href="/">Home /</a></li>
                      <li className='active-bread text-decoration-none'><a href="#">Tires</a></li>
                    </ul>

                  </div>

                </div>
              </div>
              <h2 className="text-center txt-main-listing">Tires</h2>
            </div>

          </section>

          <section>
            <div className="main-section-lsting wheelsPageSectioin">
              <div className="container">

                <div className="row">
                  <div className="col-lg-3">
                    <div className={`main-outer-filter ${filterClass}`}>
                      <div className="filter-crose" id="close-bar">
                        {/* <button className="submit" onClick={hideFilterClass}>
                          Submit
                        </button> */}
                        <button className="abc" onClick={hideFilterClass}>
                          <img src="/images/x-mark.svg" alt="img" height="100%" width="100%" />
                        </button>
                      </div>

                      <div className="price-filter filter-button">
                        <div>
                          <button className='btn btn-danger' onClick={onclickFilter}>APPLY FILTER</button>
                        </div>
                      </div>

                      
                      <div className="price-filter brandFilter ">
                      
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenBrand(!openBrand)} aria-expanded={openBrand} aria-controls="brand-collapse-text">
                            Brands <i className={openBrand ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openBrand}>
                            <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                              {

                                dataTires.filters && dataTires.filters.uniqueBrandFirstTime ?
                                  dataTires.filters.uniqueBrandFirstTime.map((item, index) => (
                                    <li key={item.brand_id}>
                                      <label className='label-filter'>
                                        <input type="checkbox"
                                          id={"brand" + item.brand_id + index}
                                          name={item.brand}
                                          value={item.brand_id}
                                          className='filter-box'
                                          checked={brandArray.includes(String(item.brand_id))}
                                          onChange={handleBrandCheckboxChange}
                                        /> {item.brand}
                                        <label htmlFor={"brand" + item.brand_id + index} className='custome-checkboxes'></label>

                                      </label>
                                    </li>
                                  ))
                                  : <ShimmerThumbnail height={10} rounded />
                              }
                            </ul>
                          </Collapse>
                        
                      </div>

                      <div className="price-filter  modelFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenModel(!openModel)} aria-expanded={openModel} aria-controls="width-collapse-text">
                          Model <i className={openModel ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>

                        <Collapse in={openModel}>
                          <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueModelFirstTime ?
                                dataTires.filters.uniqueModelFirstTime.filter(item => item.line).map((item, index) => (
                                  <li key={item.line}>
                                    <label className='label-filter'>
                                      <input
                                        type="checkbox"
                                        id={"width" + item.line}
                                        name={item.line}
                                        value={item.line}
                                        className='filter-box'
                                        checked={modelArray.includes(String(item.line))}
                                        onChange={handleModelCheckboxChange}
                                      />
                                      {item.line}
                                      <label htmlFor={"width" + item.line} className='custome-checkboxes'></label>
                                    </label>

                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>

                      <div className="price-filter widthFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenWidth(!openWidth)} aria-expanded={openWidth} aria-controls="example-collapse-text">
                          Tire Height <i className={openWidth ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openWidth}>
                          <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueWidthFirstTime && dataTires.filters.uniqueWidthFirstTime.length > 0 ?
                                dataTires.filters.uniqueWidthFirstTime.map((item, index) => (
                                  <li key={"width" + item}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={"width" + item}
                                        name={item}
                                        value={item}
                                        className='filter-box'
                                        checked={widthArray.includes(String(item))}
                                        onChange={handleWidthCheckboxChange}
                                      /> {item + '"'}
                                      <label htmlFor={"width" + item} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>

                      <div className="price-filter ratioFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setopenRatio(!openRatio)} aria-expanded={openRatio} aria-controls="example-collapse-text">
                          Tire Ratio <i className={openRatio ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openRatio}>
                          <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueratioFirstTime && dataTires.filters.uniqueratioFirstTime.length > 0 ?
                                dataTires.filters.uniqueratioFirstTime.map((item, index) => (
                                  <li key={"ratio" + item}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={"ratio" + item}
                                        name={item}
                                        value={item}
                                        className='filter-box'
                                        checked={ratioArray.includes(String(item))}
                                        onChange={handleRatioCheckboxChange}
                                      /> {item}
                                      <label htmlFor={"ratio" + item} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>


                      <div className="price-filter diameterFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setopenDiameter(!openDiameter)} aria-expanded={openDiameter} aria-controls="example-collapse-text">
                          Tire Diameter <i className={openDiameter ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openDiameter}>
                          <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueDiameterFirstTime && dataTires.filters.uniqueDiameterFirstTime.length > 0 ?
                                dataTires.filters.uniqueDiameterFirstTime.map((item, index) => (
                                  <li key={"diameter" + item}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={"diameter" + item}
                                        name={item}
                                        value={item}
                                        className='filter-box'
                                        checked={diameterArray.includes(String(item))}
                                        onChange={handleDiameterCheckboxChange}
                                      /> {item + '"'}
                                      <label htmlFor={"diameter" + item} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>









                      <div className="price-filter categoryFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenCategory(!openCategory)} aria-expanded={openCategory} aria-controls="example-collapse-text">
                          Tread Design <i className={openCategory ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>
                        <Collapse in={openCategory}>
                          <ul className='p-0 m-0 list-filter' id="example-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueCategory ?
                                dataTires.filters.uniqueCategory.map((item, index) => (
                                  <li key={"category" + item.category}>
                                    <label className='label-filter'>
                                      <input type="checkbox"
                                        id={"category" + item.category}
                                        name={item.category}
                                        value={item.category}
                                        className='filter-box'
                                        checked={categoryArray.includes(String(item.category))}
                                        onChange={handleCategoryCheckboxChange}
                                      /> {item.category}
                                      <label htmlFor={"category" + item.category} className='custome-checkboxes'></label>

                                    </label>
                                  </li>
                                ))
                                : ""
                            }
                          </ul>
                        </Collapse>
                      </div>


                      <div className="price-filter  loadRangeFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenLoadRating(!openLoadRating)} aria-expanded={openLoadRating} aria-controls="width-collapse-text">
                          Load Range <i className={openLoadRating ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>

                        <Collapse in={openLoadRating}>
                          <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueLoadRangeFirstTime ?
                                dataTires.filters.uniqueLoadRangeFirstTime.map((item, index) => (
                                  <li key={item.load_range}>
                                    <label className='label-filter'>
                                      <input
                                        type="checkbox"
                                        id={"load" + item.load_range}
                                        name={item.load_range}
                                        value={item.load_range}
                                        className='filter-box'
                                        checked={speedLoadRange.includes(String(item.load_range))}
                                        onChange={handleLoadRangeCheckboxChange}
                                      />
                                      {item.load_range}
                                      <label htmlFor={"load" + item.load_range} className='custome-checkboxes'></label>
                                    </label>

                                  </li>
                                ))
                                : ""
                            }
                          </ul>
                        </Collapse>
                      </div>



                      <div className="price-filter  speedRatingFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenSpeedRating(!openSpeedRating)} aria-expanded={openSpeedRating} aria-controls="width-collapse-text">
                          Speed Rating <i className={openSpeedRating ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>

                        <Collapse in={openSpeedRating}>
                          <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniqueSpeedRatingFirstTime ?
                                dataTires.filters.uniqueSpeedRatingFirstTime.map((item, index) => (
                                  <li key={item.speedrating}>
                                    <label className='label-filter'>
                                      <input
                                        type="checkbox"
                                        id={"speed" + item.speedrating}
                                        name={item.speedrating}
                                        value={item.speedrating}
                                        className='filter-box'
                                        checked={speedRatingArray.includes(String(item.speedrating))}
                                        onChange={handleSpeedRatingCheckboxChange}
                                      />
                                      {item.speedrating}
                                      <label htmlFor={"speed" + item.speedrating} className='custome-checkboxes'></label>
                                    </label>

                                  </li>
                                ))
                                : ""
                            }
                          </ul>
                        </Collapse>
                      </div>


                      <div className="price-filter  plyFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenPly(!openPly)} aria-expanded={openPly} aria-controls="width-collapse-text">
                          Ply <i className={openPly ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>

                        <Collapse in={openPly}>
                          <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniquePlyFirstTime ?
                                dataTires.filters.uniquePlyFirstTime.map((item, index) => (
                                  <li key={item.ply}>
                                    <label className='label-filter'>
                                      <input
                                        type="checkbox"
                                        id={"ply" + item.ply}
                                        name={item.ply}
                                        value={item.ply}
                                        className='filter-box'
                                        checked={plyArray.includes(String(item.ply))}
                                        onChange={handlePlyCheckboxChange}
                                      />
                                      {item.ply}
                                      <label htmlFor={"ply" + item.ply} className='custome-checkboxes'></label>
                                    </label>

                                  </li>
                                ))
                                : ""
                            }
                          </ul>
                        </Collapse>
                      </div>

                      <div className="price-filter  sideWallFilter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenSideWall(!openSideWall)} aria-expanded={openSideWall} aria-controls="width-collapse-text">
                          Sidewall <i className={openSideWall ? "fa fa-minus" : "fa fa-plus"}></i>
                        </h3>

                        <Collapse in={openSideWall}>
                          <ul className='p-0 m-0 list-filter' id="width-collapse-text">
                            {

                              dataTires.filters && dataTires.filters.uniquesidewallFirstTime ?
                                dataTires.filters.uniquesidewallFirstTime.map((item, index) => (
                                  <li key={item.sidewall}>
                                    <label className='label-filter'>
                                      <input
                                        type="checkbox"
                                        id={"width" + item.sidewall}
                                        name={item.sidewall}
                                        value={item.sidewall}
                                        className='filter-box'
                                        checked={sideWallArray.includes(String(item.sidewall))}
                                        onChange={handleSideWallCheckboxChange}
                                      />
                                      {item.sidewall}
                                      <label htmlFor={"width" + item.sidewall} className='custome-checkboxes'></label>
                                    </label>

                                  </li>
                                ))
                                : <ShimmerThumbnail height={10} rounded />
                            }
                          </ul>
                        </Collapse>
                      </div>
                      {
                        dataTires.filters && dataTires.filters.uniqueMilageFirstTime && dataTires.filters.uniqueMilageFirstTime.length > 0 ?
                          <div className="price-filter milageRating ">
                            <>
                              <h3 className="price-filter-text" role="button" onClick={() => setOpenMilageRating(!openMilageRating)} aria-expanded={openMilageRating} aria-controls="mileage-collapse-text">
                                Mileage Rating <i className={openMilageRating ? "fa fa-minus" : "fa fa-plus"}></i>
                              </h3>
                              <Collapse in={openMilageRating}>
                                <ul className='p-0 m-0 list-filter' id="mileage-collapse-text">
                                  {

                                    dataTires.filters && dataTires.filters.uniqueMilageFirstTime ?
                                      dataTires.filters.uniqueMilageFirstTime.map((item, index) => (
                                        <li key={item}>
                                          <label className='label-filter'>
                                            <input
                                              type="checkbox"
                                              id={item}
                                              name={item}
                                              value={item}
                                              className='filter-box'
                                              checked={milageRatingArray.includes(String(item))}
                                              onChange={handleMilageRatingCheckboxChange}
                                            />
                                            {item}
                                            <label htmlFor={item} className='custome-checkboxes'></label>
                                          </label>

                                        </li>
                                      ))
                                      : <ShimmerThumbnail height={10} rounded />
                                  }
                                </ul>
                              </Collapse>
                            </>
                          </div>

                          : ""
                      }


                      {/* <div className="price-filter warranty ">
                    <>
                      <h3 className="price-filter-text" role="button" onClick={() => setOpenWarranty(!openWarranty)} aria-expanded={openWarranty} aria-controls="brand-collapse-text">
                        Warranty <i className={openWarranty ? "fa fa-minus" : "fa fa-plus"}></i>
                      </h3>
                      <Collapse in={openWarranty}>
                        <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="warranty1"
                                name="warranty"
                                value="one"
                                className='filter-box'
                                onChange={handleWarrentyCheckboxChange}
                                checked={warranty == 'one'}
                              /> Yes
                              <label htmlFor="warranty1" className='custome-checkboxes'></label>
                            </label>
                          </li>
                          <li>
                            <label className='label-filter'>
                              <input type="checkbox"
                                id="warranty2"
                                name="warranty"
                                value="zero"
                                className='filter-box'
                                onChange={handleWarrentyCheckboxChange}
                                checked={warranty == 'zero'}
                              /> No
                              <label htmlFor="warranty2" className='custome-checkboxes'></label>
                            </label>
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  </div> */}







                      <div className="price-filter runFlat ">
                        <>
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenRunFlat(!openRunFlat)} aria-expanded={openRunFlat} aria-controls="brand-collapse-text">
                            Run Flat <i className={openRunFlat ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openRunFlat}>
                            <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                              <li>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id="run_flat"
                                    name=""
                                    value="one"
                                    className='filter-box'
                                    onChange={handleRunFlatCheckboxChange}
                                    checked={runflat == 'one'}
                                  /> Yes
                                  <label htmlFor="run_flat" className='custome-checkboxes'></label>
                                </label>
                              </li>
                              <li>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id="run_flat2"
                                    name=""
                                    value="zero"
                                    className='filter-box'
                                    onChange={handleRunFlatCheckboxChange}
                                    checked={runflat == 'zero'}
                                  /> No
                                  <label htmlFor="run_flat2" className='custome-checkboxes'></label>
                                </label>
                              </li>
                            </ul>
                          </Collapse>
                        </>
                      </div>

                      <div className="price-filter studdable ">
                        <>
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenStuddable(!openStuddable)} aria-expanded={openStuddable} aria-controls="brand-collapse-text">
                            Studdable <i className={openStuddable ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openStuddable}>
                            <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                              <li>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id="studdable"
                                    name=""
                                    value="one"
                                    className='filter-box'
                                    onChange={handleStuddableCheckboxChange}
                                    checked={studdable == 'one'}
                                  /> Yes
                                  <label htmlFor="studdable" className='custome-checkboxes'></label>
                                </label>
                              </li>
                              <li>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id="studdable2"
                                    name=""
                                    value="zero"
                                    className='filter-box'
                                    onChange={handleStuddableCheckboxChange}
                                    checked={studdable == 'zero'}
                                  /> No
                                  <label htmlFor="studdable2" className='custome-checkboxes'></label>
                                </label>
                              </li>
                            </ul>
                          </Collapse>
                        </>
                      </div>


                      <div className="price-filter sizes ">
                        <>
                          <h3 className="price-filter-text" role="button" onClick={() => setOpenTireSizes(!openTireSizes)} aria-expanded={openTireSizes} aria-controls="brand-collapse-text">
                            Tire Sub Type <i className={openTireSizes ? "fa fa-minus" : "fa fa-plus"}></i>
                          </h3>
                          <Collapse in={openTireSizes}>
                            <ul className='p-0 m-0 list-filter' id="brand-collapse-text">
                              <li>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id="commonsizes"
                                    name=""
                                    value="one"
                                    className='filter-box'
                                    onChange={handleTireSizesCheckboxChange}
                                    checked={tiresizes == 'one'}
                                  /> Car/Truck
                                  <label htmlFor="commonsizes" className='custome-checkboxes'></label>
                                </label>
                              </li>
                              <li>
                                <label className='label-filter'>
                                  <input type="checkbox"
                                    id="allsizestire"
                                    name=""
                                    value="zero"
                                    className='filter-box'
                                    onChange={handleTireSizesCheckboxChange}
                                    checked={tiresizes == 'zero'}
                                  /> All Sizes
                                  <label htmlFor="allsizestire" className='custome-checkboxes'></label>
                                </label>
                              </li>
                            </ul>
                          </Collapse>
                        </>
                      </div>

                      <div className="price-filter">
                        <h3 className="price-filter-text" role="button" onClick={() => setOpenPrice(!openPrice)} aria-expanded={openPrice} aria-controls="price-collapse-text">
                          Price
                          <i
                            className={openPrice ? "fa fa-minus" : "fa fa-plus"}
                          >

                          </i>
                        </h3>
                        {
                          !isLoadingWheels && minPrice !== null && maxPrice !== null ?
                            <Collapse in={openPrice}>
                              <div id='price-collapse-text'>
                                <form >
                                  <div className="aside-price-range-form d-flex  m-3" >
                                    <div className="form-field me-2" style={{ width: '80%' }}>
                                      {/* <label htmlFor="filter-price-min">$ Min</label> */}
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={"$" + minPrice}
                                        id="minPrice"
                                        placeholder={"$" + minPrice}
                                        autoComplete="off"
                                        maxLength="10"
                                        style={{ width: '100%' }}
                                        disabled={true}
                                      />
                                    </div>
                                    <div className="form-field me-2" style={{ width: '80%' }}>
                                      {/* <label htmlFor="filter-price-max">$ Max</label> */}
                                      <input
                                        className="form-control"
                                        type="text"
                                        id="maxPrice"
                                        value={"$" + maxPrice}
                                        placeholder={"$" + maxPrice}
                                        autoComplete="off"
                                        maxLength="10"
                                        style={{ width: '100%' }}
                                        disabled={true}
                                      />
                                    </div>

                                  </div>
                                </form>

                                <div className="">
                                  <RangeSlider
                                    min={0}
                                    step={1}
                                    max={maxPriceDefault}
                                    defaultValue={[minPrice, maxPrice]}
                                    onChangeCommitted={handleChangeSlider}
                                    valuelabeldisplay="on"

                                  />
                                </div>
                              </div>
                            </Collapse> :
                            ""}
                      </div>



                    </div>
                  </div>
                  <div className="col-lg-9 filter-for-mobile">

                    <div className='selectedFilters '>
                      {
                        diameterArray && diameterArray.length > 0 ?
                          diameterArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveDiameter(item)}></i>
                            </span>
                          ))
                          : ""
                      }


                      {
                        widthArray && widthArray.length > 0 ?
                          widthArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item + '"'} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveWidth(item)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        brandNameArray && brandNameArray.length > 0 ?
                          brandNameArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item.id}>
                              <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        categoryNameArray && categoryNameArray.length > 0 ?
                          categoryNameArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item.id}>
                              <span> {item.name} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveBrand(item.id)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        modelArray && modelArray.length > 0 ?
                          modelArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveModel(item)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {

                        ratioArray && ratioArray.length > 0 ?
                          ratioArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveRatio(item)}></i>
                            </span>
                          ))
                          : ""
                      }

                      {
                        speedLoadRange && speedLoadRange.length > 0 ?
                          speedLoadRange.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemoveLoadRange(item)}></i>
                            </span>
                          ))

                          : ""
                      }

                      {
                        speedRatingArray && speedRatingArray.length > 0 ?
                          speedRatingArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedSpeedRating(item)}></i>
                            </span>
                          ))

                          : ""
                      }

                      {
                        plyArray && plyArray.length > 0 ?
                          plyArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedPly(item)}></i>
                            </span>
                          ))

                          : ""
                      }

                      {

                        sideWallArray && sideWallArray.length > 0 ?
                          sideWallArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedSideWall(item)}></i>
                            </span>
                          ))

                          : ""

                      }

                      {

                        milageRatingArray && milageRatingArray.length > 0 ?
                          milageRatingArray.map((item, index) => (
                            <span className="span-filter-Checkbox m-1" key={item}>
                              <span> {item} </span><i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => handleRemovedMilageRating(item)}></i>
                            </span>
                          ))

                          : ""

                      }


                      {
                        diameterArray.length > 0 || brandNameArray.length > 0 || categoryNameArray.length > 0 || speedRatingArray.length > 0 || speedLoadRange.length > 0 || ratioArray.length > 0 || modelArray.length > 0 || categoryNameArray.length > 0 || widthArray.length > 0 || plyArray.length > 0 || sideWallArray.length > 0 || milageRatingArray.length > 0 ?

                          <span className="clearFilterStyle" onClick={clearAllFilters} >
                            <span>Clear filters</span>
                          </span>

                          :

                          ""
                      }

                    </div>

                    <div className="d-flex justify-content-end filters-sort-by">
                      <div className="">

                        {totalProducts > 0 ?
                          <p className="px-5">
                            Showing {startIndex} - {endIndex} of {parseInt(totalProducts).toLocaleString('en-US')} products

                          </p>
                          : ""

                        }
                      </div>
                      <div className='stockCheckbox'>
                        <ul className='stockCheckboxUl'>
                          <li>
                            <input
                              type="checkbox"
                              value="2"
                              className='tgl tgl-light'
                              onChange={handleChangeStockAvail}
                              id='stockChecki'
                              name="stockCheck"
                              checked={!isCheckedStock} // Set the checked attribute based on isChecked
                            />
                            <label htmlFor='stockChecki' className='tgl-btn'></label>
                          </li>
                          <li className='staggeredLi'>
                            {
                              isCheckedStock ?
                                <span>In Stock Items</span>
                                : <span>All Items</span>
                            }
                          </li>
                        </ul>
                      </div>
                      <div className="">
                        <select className="form-control" name="sorting" id="sortingWithPrice" onChange={handleSorting} value={sortingValue}>
                          <option disabled >Sort By Price</option>
                          <option value="1" >Low-High</option>
                          <option value="2">High-Low</option>
                        </select>

                      </div>
                      <div className="main-headin">
                        <div className="main-filter-resposive">
                          <button className="mobile-filter-btn" onClick={showFiltersClick}> <svg className="me-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25901 14.35L5.25888 14.35C5.24769 14.3502 5.23405 14.3476 5.21863 14.339L5.21836 14.3389C5.19936 14.3282 5.19074 14.318 5.18703 14.3124C5.17887 14.2972 5.176 14.2839 5.176 14.2707V7.91365C5.176 7.82275 5.1652 7.72056 5.13235 7.61486C5.09945 7.50839 5.04961 7.41561 4.99386 7.33726L0.724519 1.26892C0.724485 1.26887 0.724451 1.26882 0.724418 1.26877C0.676725 1.20083 0.65495 1.13291 0.650688 1.06991C0.64665 1.00345 0.660203 0.933143 0.696549 0.863781L0.696552 0.863774C0.732993 0.794228 0.783357 0.74264 0.84197 0.707465L0.841971 0.707466L0.843135 0.706765C0.902543 0.670943 0.973036 0.65 1.05357 0.65H12.9463C13.0271 0.65 13.0973 0.670932 13.1564 0.706609L13.1578 0.70745C13.2164 0.742594 13.2667 0.794096 13.3032 0.863535C13.3407 0.935416 13.3534 1.00516 13.3492 1.06874C13.345 1.13245 13.3232 1.20066 13.2755 1.26862C13.2755 1.26872 13.2754 1.26882 13.2753 1.26892L9.0045 7.33779L9.00402 7.33848C8.94618 7.42089 8.90098 7.51067 8.86985 7.6068L8.86979 7.60678L8.8672 7.61518C8.83481 7.71999 8.82355 7.82177 8.82355 7.91441V12.1555C8.82355 12.1724 8.81984 12.1838 8.81525 12.1923C8.81075 12.1999 8.8025 12.2101 8.78506 12.2207L8.34805 12.4857L8.34827 12.4864L5.3042 14.3361C5.30409 14.3362 5.30399 14.3362 5.30388 14.3363C5.28736 14.3462 5.27261 14.3497 5.25901 14.35ZM5.27154 14.9999C5.14389 15.0023 5.01635 14.9707 4.90099 14.9061L5.27154 14.9999Z" stroke="#E81C2E" strokeWidth="1.3"></path>
                          </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='row tiresCards'>
                      {


                        !isLoadingWheels && dataTires ?
                          dataTires.data && dataTires.data.length > 0 ?
                            dataTires.data.map((itm, idx) => {
                              return (<TiresItemscard data={itm} key={itm.item_id + idx} />);
                            })
                            : <>
                              <div className='container m-0 text-center'>
                                <h1>No Product Found</h1>
                                <p>Sorry, we couldn't find any products matching your search criteria.</p>
                                <p><a href='/' className=' btn btn-sm btn-danger'>Click here</a></p>
                              </div>

                            </>
                          :
                          Array.from({ length: 28 }).map((_, idx) => (
                            <div className="col-lg-3 col-6" key={idx}>
                              <a href="#">
                              </a>
                              <div className="Drivetrain-cart"><a href="">
                                <div className="engle-imge-upper">
                                  {/* <button className="hover-btn">View Details</button> */}
                                  <ShimmerThumbnail height={163} rounded />
                                </div>
                              </a>
                                <div className="cart-body">
                                  <a href="#">
                                    <h6><ShimmerButton size="sm" /></h6>
                                    <span className="m-0 description text-dark" ><ShimmerButton size="md" width="100%" /></span>


                                  </a>
                                  <div className="price-cart">
                                    <a href="#">
                                      <div className="price">

                                      </div>
                                    </a>
                                    <div className="cart-btn">
                                      <a href="#">
                                      </a>
                                      <a href="#">

                                        {/* <img src={data.data.brand_logo} alt="" /> */}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          ))

                      }


                    </div>
                    {totalPages > 0 &&
                      <div className="d-flex mt-4 justify-content-center">
                        <Pagination>
                          <Pagination.First
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(1)}
                          />
                          <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                          />


                          {getPageNumbers().map((page) => (
                            <Pagination.Item
                              key={page}
                              active={currentPage === page}
                              onClick={() => {
                                if (currentPage !== page) {
                                  handlePageChange(page);
                                }
                              }}
                            >
                              {page}
                            </Pagination.Item>
                          ))}

                          <Pagination.Next
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                          />
                          <Pagination.Last
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(totalPages)}
                          />
                        </Pagination>
                      </div>
                    }
                  </div>

                </div>
              </div>
            </div>


          </section >
        </>

      )
        : (<div className="error-screen">

          <h2>Product Not Found</h2>
          <button className="btn btn-sm btn-danger" onClick={goBackToHomepage}>
            Go back to homepage
          </button>
        </div>)}
      <FOOTER />


    </>
  )
}  